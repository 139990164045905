import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@talentech/components";
import { apiUrl } from "@utils/urlsUtils";
import { ITags } from "tag-types";

enum TagTypes {
  TAGS = "TAGS",
}

export const tagsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl }),
  reducerPath: "tagsApi",
  tagTypes: [TagTypes.TAGS],
  endpoints: (build) => ({
    getTags: build.query<ITags[], unknown>({
      query: () => ({
        method: "GET",
        url: `/v1/Tags`,
      }),
    }),
  }),
});

export const { useGetTagsQuery } = tagsApi;

export const tagsApiReducer = {
  [tagsApi.reducerPath]: tagsApi.reducer,
};
