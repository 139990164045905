import React, { FC } from "react";
import {
  TalentechIcons,
  Avatar,
  TalentechAvatarSizes,
  Box,
  Icon,
  TalentechSizes,
  Typography,
} from "@talentech/components";
import Rating from "react-rating";
import { IEachProjectCandidate } from "project-candidate-types";
import { ratingMin, ratingMax, ratingStep } from "@utils/constants";

const TagList: FC<{ tags: IEachProjectCandidate["candidateTagList"] }> = ({
  tags,
}) => (
  <Box flex className="flex-wrap mt-3 gap-2">
    {tags.map((tag) => (
      <Box flex key={tag.tagId} className="align-items-center">
        <Icon
          className="me-1 text-100"
          iconSize={TalentechSizes.SMALL}
          icon={
            TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_TAGS_BOOKMARKS_TAGS
          }
        />
        <Typography className="text-100">{tag.name}</Typography>
      </Box>
    ))}
  </Box>
);

const ProjectCandidatesListGroup = (candidate: IEachProjectCandidate) => {
  const ratingValue = Math.max(
    ratingMin,
    candidate.candidateHiringCommitteeAverageRankNumber
  );

  return [
    {
      title: `${candidate.firstName} ${candidate.lastName}`,
      advancedProps: {
        richContent: candidate.candidateTagList.length > 0 && (
          <TagList tags={candidate.candidateTagList} />
        ),
        subtitle: candidate?.hiringCommitteeEnabled &&
          candidate?.canSeeAvergaeHiringCommitteeScore && (
            <Rating
              className="mt-1"
              readonly
              start={ratingMin}
              stop={ratingMax}
              step={ratingStep}
              initialRating={ratingValue}
              emptySymbol={
                <Icon
                  iconSize={TalentechSizes.LARGE}
                  icon={
                    TalentechIcons.LIGHT_22_SOCIAL_MEDIAS_REWARDS_RATINGS_RATING_RATING_STAR
                  }
                />
              }
              fullSymbol={
                <Icon
                  iconSize={TalentechSizes.LARGE}
                  icon={
                    TalentechIcons.BOLD_22_SOCIAL_MEDIAS_REWARDS_RATINGS_RATING_RATING_STAR
                  }
                />
              }
            />
          ),
        contentImage: (
          <Avatar
            outline
            avatarUrl={candidate.imageUrl}
            size={TalentechAvatarSizes.XXL}
            icon={
              TalentechIcons.REGULAR_17_USERS_GEOMETRIC_CLOSE_UP_SINGLE_USER_NEUTRAL_SINGLE_NEUTRAL
            }
          />
        ),
        appendix: (
          <Box flex className="ms-auto flex-wrap gap-2">
            {candidate?.videoCvUrl && (
              <Icon
                className="text-100"
                iconSize={TalentechSizes.LARGE}
                icon={
                  TalentechIcons.REGULAR_07_WORK_OFFICE_COMPANIES_VIDEO_MEETINGS_MEETING_CAMERA
                }
              />
            )}
            {candidate?.linkedInPublicProfileUrl && (
              <Icon
                className="text-100"
                iconSize={TalentechSizes.LARGE}
                icon={TalentechIcons.BOLD_53_LOGOS_SOCIAL_MEDIAS_LINKEDIN}
              />
            )}
          </Box>
        ),
      },
    },
  ];
};

export default ProjectCandidatesListGroup;
