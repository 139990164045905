import {
  Box,
  Grid,
  Icon,
  ProgressBar,
  TalentechColors,
  TalentechIcons,
  TalentechSizes,
  Tooltip,
  Typography,
} from "@talentech/components";
import classNames from "classnames";
import { IEachProject } from "project-types";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ProjectStatusLoader from "@components/Loaders/ProjectStatusLoader";
import { Redirect } from "react-router";
interface ProjectsListProps {
  projects: IEachProject[];
}

const ProjectsList: React.FC<ProjectsListProps> = ({ projects }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      {projects.map((project) => (
        <Box
          key={project.id}
          onClick={() => history.push(`/project/${project.id}?t=` + Date.now())}
        >
          <Grid xs={12}>
            <Box flex className="w-100 mb-1 justify-content-between">
              <Box flex className="align-items-center ">
                <Tooltip
                  content={`${t("mobapp.projectlist.tooltip.projectid")}: ${
                    project.id
                  }`}
                >
                  <Icon
                    className="text-primary me-2"
                    icon={
                      TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_ALERTS_INFORMATION_CIRCLE
                    }
                  />
                </Tooltip>
                <Typography className="mb-1 fw-semi-bold lh-sm">
                  {project.name}
                </Typography>
              </Box>

              <Box flex className="align-items-center">
                <Typography
                  className={classNames("mb-0", "me-1", {
                    "fw-bold": project.newCandidateCount,
                  })}
                >
                  {project.allCandidateCount}
                </Typography>
                <Tooltip
                  content={
                    project.publishingStatus === 0
                      ? `${t("mobapp.projectlist.tooltip.published")}`
                      : project.publishingStatus === 1
                      ? `${t("mobapp.projectlist.tooltip.notpublishedyet")}`
                      : project.publishingStatus === 2
                      ? `${t("mobapp.projectlist.tooltip.unpublished")}`
                      : `${t("mobapp.projectlist.tooltip.unknown_status")}`
                  }
                >
                  <i
                    className={classNames("fas", {
                      "fa-check-circle text-success":
                        project.publishingStatus == 0,
                      "fas fa-minus-circle text-gray":
                        project.publishingStatus == 1,
                      "fa-pause-circle text-danger":
                        project.publishingStatus == 2,
                    })}
                  ></i>
                </Tooltip>
              </Box>
            </Box>

            <Box
              flex
              className="w-100 justify-content-between align-items-center"
            >
              <Box className="flex-grow-1">
                <ProgressBar
                  color={TalentechColors.PRIMARY}
                  value={project.projectProgressStep * 20}
                />
              </Box>
              <Typography className="text-muted mb-0 ms-3 small">
                {t("mobapp.projectlist.label.duein")}{" "}
                {project.applicationDueInDays}{" "}
                {t("mobapp.projectlist.label.days")}
              </Typography>
            </Box>
            <Box
              flex
              className="w-100 justify-content-between align-items-center"
            >
              <ProjectStatusLoader value={project.projectProgressStep} />
            </Box>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default ProjectsList;
