import { useSelector } from "react-redux";
import React from "react";
import { Sidebar } from "@talentech/components";
import { getOidcLanguage } from "@talentech/login";

const AppSidebar = (props: { toggleSidebar?(): void }) => {
  const oidcLang: string = useSelector(getOidcLanguage);
  return (
    <Sidebar
      activeApp={{
        name: "Talent Recruiter",
        disclaimer: "by Talentech",
        id: "Talent Recruiter",
        appIconUrl:
          "https://storybook.talentech.io/images/svg/products/light/recruitment.svg",
      }}
      activeLanguage={oidcLang}
      appSwitcher={[]}
      menu={[]}
      {...props}
    />
  );
};

export default AppSidebar;
