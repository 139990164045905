import React, { useCallback, useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router";
import styled from "styled-components";
import {
  Box,
  Grid,
  Icon,
  PageTitle,
  ProgressBar,
  TalentechColors,
  TalentechIcons,
  Tooltip,
  Typography,
  Button,
} from "@talentech/components";
import { useGetProjectsQuery } from "@store/services/projectsService";
import { useGetProjectCandidatesQuery } from "@store/services/projectsCandidatesService";
import ProjectCandidatesList from "./fragments/ProjectCandidatesList";
import ProjectDetailsLoader from "@components/Loaders/ProjectDetialsLoader";
import { useTranslation } from "react-i18next";
import ProjectStatusLoader from "@components/Loaders/ProjectStatusLoader";

const StyledBoxHeader = styled(Box)`
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: #fbfbfc;
  box-shadow: inset 0px -1px 0px #d6d6dc;
`;

const ProjectDetails: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [sortCandidateByRating, setSortCandidateByRating] = useState(false);
  const { t } = useTranslation();
  const {
    project,
    isProjectsFetching,
    refetch: refetchProjects,
  } = useGetProjectsQuery(
    { projectId },
    {
      selectFromResult: ({ data, isFetching }) => ({
        project: data?.elements || [],
        isProjectsFetching: isFetching,
      }),
    }
  );

  const handleSortToggle = useCallback(() => {
    setSortCandidateByRating((prevState) => !prevState);
  }, []);

  const {
    projectCandidates,
    isCandidatesFetching,
    refetch: refetchProjectCandidates,
  } = useGetProjectCandidatesQuery(
    {
      id: projectId,
      ascending: sortCandidateByRating,
      orderBy: "AverageScore",
    },
    {
      selectFromResult: ({ data, isFetching }) => ({
        projectCandidates: data?.elements || [],
        isCandidatesFetching: isFetching,
      }),
      skip: !project.length,
    }
  );
  const history = useHistory();
  const location = useLocation(); // Get the current location

  // useEffect(() => {
  //   // Check if the refresh query parameter is present
  //   const queryParams = new URLSearchParams(location.search);
  //   const refresh = queryParams.get("r");

  //   if (refresh === "true") {
  //     if (project.length > 0) {
  //       // Refetch project data
  //       refetchProjects();
  //     }
  //     if (project.length > 0) {
  //       // Refetch project candidates data
  //       refetchProjectCandidates();
  //     }
  //   }
  // }, [location.search]);

  // if (isProjectsFetching || isCandidatesFetching)
  //   return <ProjectDetailsLoader />;

  return (
    <>
      {project.map((project) => (
        <React.Fragment key={project.id}>
          <PageTitle
            title={project.name}
            additionalContent={
              <Box
                flex
                className="w-100 justify-content-start align-items-center"
              >
                <Tooltip
                  content={`${t("mobapp.projectlist.tooltip.projectid")}: ${
                    project.id
                  }`}
                >
                  <Icon
                    className="text-primary me-1"
                    icon={
                      TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_ALERTS_INFORMATION_CIRCLE
                    }
                  />
                </Tooltip>
                <Box className="flex-grow-1">
                  <ProgressBar
                    color={TalentechColors.PRIMARY}
                    value={project.projectProgressStep * 20}
                  />
                  <Box
                    flex
                    className="w-100 justify-content-between align-items-center"
                  >
                    <ProjectStatusLoader value={project.projectProgressStep} />
                  </Box>
                </Box>
              </Box>
            }
          />
          <Grid container className="mt-2">
            <StyledBoxHeader className="square rounded p-3 d-flex justify-content-around">
              <Box
                flex
                className="w-50 flex-column align-items-center border-end border-gray-300"
              >
                <Typography className="display-6 mb-0">
                  {project.allCandidateCount}
                </Typography>
                <Typography className="lh-base fs-6">
                  {t("mobapp.projectdetails.label.applications")}
                </Typography>
              </Box>
              <Box flex className="w-50 flex-column align-items-center">
                <Typography className="display-6 mb-0">
                  {project.newCandidateCount}
                </Typography>
                <Typography className="lh-base fs-6">
                  {t("mobapp.projectdetails.label.new")}
                </Typography>
              </Box>
            </StyledBoxHeader>
            {!isCandidatesFetching ? (
              <ProjectCandidatesList
                {...{
                  handleSortToggle,
                  projectCandidates,
                }}
              />
            ) : (
              <Box className="placeholder-glow">
                {[...Array(8).keys()].map((index) => (
                  <span key={index} className="placeholder col-12 py-3 mb-2" />
                ))}
              </Box>
            )}
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

export default ProjectDetails;
