import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonColors,
  Icon,
  Modal,
  TalentechIcons,
  TalentechSizes,
} from "@talentech/components";
import Rating from "react-rating";
import { IProjectCandidateDetails } from "project-candidate-types";
import { useTranslation } from "react-i18next";
import {
  ICommonTypes,
  useSetCandidateHiringCommitteeRankMutation,
} from "@store/services/projectsCandidatesService";
import { ratingMin, ratingMax, ratingStep } from "@utils/constants";

interface IStarRatingModal extends ICommonTypes {
  showModal: boolean;
  handleCloseModal: () => void;
  candidateDetails: IProjectCandidateDetails;
}

const StarRatingModal: React.FC<IStarRatingModal> = ({
  showModal,
  handleCloseModal,
  projectId,
  candidateId,
  candidateDetails,
}) => {
  const [hiringRank, setHiringRank] = useState<number>();
  const [
    setHiringCommitteeRank,
    { isLoading },
  ] = useSetCandidateHiringCommitteeRankMutation();
  const { t } = useTranslation();
  const handleSubmit = async () => {
    await setHiringCommitteeRank({
      projectId,
      candidateId,
      hiringCommitteeRankId: hiringRank,
      comment: "",
    });

    handleCloseModal();
  };

  const initialRating = Math.max(
    ratingMin,
    candidateDetails?.candidateHiringCommitteeIndividualRankNumber
  );

  return (
    <Modal
      title={t("mobapp.canddetails.title.starrating")}
      subtitle={`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
      buttons={[
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          className="w-100"
          color={ButtonColors.PRIMARY}
          onClick={handleSubmit}
          label={t("mobapp.common.save")}
          key="save"
        />,
        <Button
          className="w-100"
          outline
          onClick={handleCloseModal}
          label={t("mobapp.common.cancel")}
          key="cancel"
        />,
      ]}
      showModal={showModal}
      onClose={handleCloseModal}
    >
      <Box flex className="justify-content-center">
        <Rating
          start={ratingMin}
          stop={ratingMax}
          step={ratingStep}
          initialRating={initialRating}
          onChange={(value) => setHiringRank(value)}
          emptySymbol={
            <Icon
              className="px-1"
              iconSize={TalentechSizes.LARGE}
              icon={
                TalentechIcons.LIGHT_22_SOCIAL_MEDIAS_REWARDS_RATINGS_RATING_RATING_STAR
              }
            />
          }
          fullSymbol={
            <Icon
              className="px-1"
              iconSize={TalentechSizes.LARGE}
              icon={
                TalentechIcons.BOLD_22_SOCIAL_MEDIAS_REWARDS_RATINGS_RATING_RATING_STAR
              }
            />
          }
        />
      </Box>
    </Modal>
  );
};

export default StarRatingModal;
