import { NavigationRehydrator, isRoutePersisted } from "@talentech/login";
import { AppRoutes } from "@utils/enums";
import React from "react";
import { hot } from "react-hot-loader/root";
import { Route, useHistory } from "react-router-dom";
import ProjectDetails from "@pages/projectDetails/ProjectDetails";
import Recruitments from "@pages/recruitments/Recruitments";
import ErrorPage from "@pages/error/ErrorPage";
import ProjectCandidate from "@pages/projectCandidate/ProjectCandidate";

const HomePage: React.FC = () => {
  return isRoutePersisted() ? (
    <NavigationRehydrator navigationPush={useHistory().push} />
  ) : (
    <Recruitments />
  );
};

const Routes = (): JSX.Element => (
  <>
    <Route path={AppRoutes.Home} exact component={HomePage} />
    <Route
      path={AppRoutes.ProjectDetailsPage}
      exact
      component={ProjectDetails}
    />
    <Route
      path={AppRoutes.ProjectCandidate}
      exact
      component={ProjectCandidate}
    />
    <Route path={AppRoutes.Error} component={ErrorPage} />
  </>
);

export default hot(Routes);
