import Axios, { AxiosRequestConfig } from "axios";
import store from "@store/store";
import { getOidcAccessToken } from "@talentech/login";
import { apiUrl, dashboardApiUrl } from "./urlsUtils";

const authorizedEndpoints = [apiUrl, dashboardApiUrl];

const shouldUseInterceptor = (config: AxiosRequestConfig) =>
  authorizedEndpoints.some((endpoint) =>
    (config.url as string).includes(endpoint)
  );

export const enhanceApiRequestsWithAccessToken = () => {
  Axios.interceptors.request.use(
    (config) => {
      config.withCredentials = true;
      if (!shouldUseInterceptor(config)) {
        return config;
      }

      config.headers.authorization = `Bearer ${getOidcAccessToken(
        store.getState()
      )}`;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
