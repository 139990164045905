import {
  Avatar,
  BootstrapSizes,
  Box,
  Button,
  Icon,
  TalentechAvatarSizes,
  TalentechIcons,
  TalentechSizes,
  Typography,
} from "@talentech/components";
import { ratingMax, ratingMin, ratingStep } from "@utils/constants";
import { ModalTypes } from "@utils/enums";
import { ICandidateProfileActions } from "modal-types";
import { IProjectCandidateDetails } from "project-candidate-types";
import React, { FC, useState } from "react";
import Rating from "react-rating";
import styled from "styled-components";

const StyledRatingIcon = styled(Icon)`
  padding: 0 5px;
  cursor: pointer;
`;

interface ProjectCandidateHeadProps {
  isFirst: boolean;
  isLast: boolean;
  isSingleCandidate: boolean;
  candidateDetails: IProjectCandidateDetails;
  handleSwitchCandidate: (i: number) => void;
  handleOpenModal: ({ modalOpen }: ICandidateProfileActions) => void;
}

const ProjectCandidateHead: FC<ProjectCandidateHeadProps> = ({
  isFirst,
  isLast,
  isSingleCandidate,
  candidateDetails,
  handleOpenModal,
  handleSwitchCandidate,
}) => {
  const initialRating = Math.max(
    ratingMin,
    candidateDetails?.candidateHiringCommitteeAverageRankNumber
  );

  const [isFullSize, setIsFullSize] = useState<boolean>(false);
  const [fullSizeSrc, setFullSizeSrc] = useState<string | null>(null); // State to store full-size image source
  const [modalOpen, setModalOpen] = useState<boolean>(false); // State to control modal visibility
  const thumbnailSrc: string | undefined = candidateDetails?.imageUrl;

  const toggleSize = (): void => {
    setIsFullSize(!isFullSize);
    setModalOpen(!modalOpen); // Toggle modal visibility
  };

  const handleAvatarClick = (): void => {
    if (thumbnailSrc) {
      setFullSizeSrc(thumbnailSrc);
      toggleSize();
    }
  };

  return (
    <>
      <Box flex className="d-flex justify-content-between">
        <Button
          disabled={isSingleCandidate || isFirst}
          className="bg-light"
          onClick={() => handleSwitchCandidate(-1)}
          icon={
            <Icon
              icon={
                TalentechIcons.REGULAR_52_ARROWS_DIAGRAMS_ARROWS_ARROW_LEFT_1
              }
            />
          }
          size={BootstrapSizes.LARGE}
        />
        <Box flex className="flex-column align-items-center">
          {modalOpen && (
            <div className="modal-overlay-preview" onClick={toggleSize}></div>
          )}
          {isFullSize && fullSizeSrc ? (
            <div className="modal-content modal-content-preview">
              <img
                src={fullSizeSrc}
                alt="Full Size Image"
                onClick={toggleSize}
              />
            </div>
          ) : (
            <Avatar
              outline
              avatarUrl={thumbnailSrc}
              icon={
                TalentechIcons.REGULAR_17_USERS_GEOMETRIC_CLOSE_UP_SINGLE_USER_NEUTRAL_SINGLE_NEUTRAL
              }
              size={TalentechAvatarSizes.XXL}
              onClick={handleAvatarClick} // Add onClick to the Avatar component
            />
          )}
          {candidateDetails?.hiringCommitteeEnabled &&
            candidateDetails?.canSeeAvergaeHiringCommitteeScore && (
              <Box className="mt-3">
                <Rating
                  readonly
                  start={ratingMin}
                  stop={ratingMax}
                  step={ratingStep}
                  initialRating={initialRating}
                  emptySymbol={
                    <StyledRatingIcon
                      onClick={() =>
                        handleOpenModal({ modalOpen: ModalTypes.ADD_RATING })
                      }
                      iconSize={TalentechSizes.LARGE}
                      icon={
                        TalentechIcons.LIGHT_22_SOCIAL_MEDIAS_REWARDS_RATINGS_RATING_RATING_STAR
                      }
                    />
                  }
                  fullSymbol={
                    <StyledRatingIcon
                      onClick={() =>
                        handleOpenModal({ modalOpen: ModalTypes.ADD_RATING })
                      }
                      iconSize={TalentechSizes.LARGE}
                      icon={
                        TalentechIcons.BOLD_22_SOCIAL_MEDIAS_REWARDS_RATINGS_RATING_RATING_STAR
                      }
                    />
                  }
                />
              </Box>
            )}
        </Box>
        <Button
          disabled={isSingleCandidate || isLast}
          className="bg-light"
          onClick={() => handleSwitchCandidate(1)}
          icon={
            <Icon
              icon={
                TalentechIcons.REGULAR_52_ARROWS_DIAGRAMS_ARROWS_ARROW_RIGHT_1
              }
            />
          }
          size={BootstrapSizes.LARGE}
        />
      </Box>
      <Box flex className="mt-3 flex-wrap justify-content-center gap-2">
        {candidateDetails?.candidateTagList?.map((tag) => (
          <Box flex key={tag.tagId} className="align-items-center">
            <Icon
              className="text-100 me-1"
              iconSize={TalentechSizes.SMALL}
              icon={
                TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_TAGS_BOOKMARKS_TAGS
              }
            />
            <Typography className="text-100 mb-0 mt-0">{tag.name}</Typography>
          </Box>
        ))}
      </Box>
      <Box
        flex
        className="flex-column justify-content-center align-items-center gap-3 my-3"
      >
        <Button
          outline
          aria-label="Add tags"
          size={BootstrapSizes.LARGE}
          onClick={() => handleOpenModal({ modalOpen: ModalTypes.ADD_TAGS })}
          className="btn btn-action btn-action-icon border border-gray-400"
          icon={
            <Icon
              icon={
                TalentechIcons.REGULAR_01_INTERFACE_ESSENTIALS_TAGS_BOOKMARKS_TAGS
              }
            />
          }
        />

        {!!candidateDetails?.videoCvUrl && (
          <Button
            outline
            size={BootstrapSizes.LARGE}
            onClick={() =>
              handleOpenModal({ modalOpen: ModalTypes.VIDEO_PREVIEW })
            }
            icon={
              <Icon
                icon={
                  TalentechIcons.LIGHT_07_WORK_OFFICE_COMPANIES_VIDEO_MEETINGS_MEETING_CAMERA
                }
              />
            }
          />
        )}
      </Box>
    </>
  );
};

export default ProjectCandidateHead;
