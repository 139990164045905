import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@talentech/components";
import { apiUrl } from "@utils/urlsUtils";
import { IProjectParams, IProjects } from "project-types";

enum TagTypes {
  PROJECTS = "PROJECTS",
}

export const projectsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl }),
  reducerPath: "projectsApi",
  tagTypes: [TagTypes.PROJECTS],
  endpoints: (build) => ({
    getProjects: build.query<IProjects, IProjectParams>({
      query: (params) => ({
        method: "GET",
        url: `/v1/Projects`,
        params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.elements.map(({ id }) => ({
                type: TagTypes.PROJECTS as const,
                id,
              })),
              { type: TagTypes.PROJECTS, id: "LIST" },
            ]
          : [TagTypes.PROJECTS],
    }),
  }),
});

export const { useGetProjectsQuery, useLazyGetProjectsQuery } = projectsApi;

export const projectsApiReducer = {
  [projectsApi.reducerPath]: projectsApi.reducer,
};
