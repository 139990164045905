export enum AppRoutes {
  Home = "/",
  Error = "/error",
  OidcCallback = "/#callback",
  ProjectDetailsPage = "/project/:projectId",
  ProjectCandidate = "/project/:projectId/candidate/:candidateId",
}
export enum ModalTypes {
  NONE = "NONE",
  ADD_NOTE = "ADD_NOTE",
  ADD_RATING = "ADD_RATING",
  ADD_TAGS = "ADD_TAGS",
  MEDIA_PREVIEW = "MEDIA_PREVIEW",
  VIDEO_PREVIEW = "VIDEO_PREVIEW",
}
