import React, { FC, useState } from "react";
import {
  Box,
  Button,
  ButtonColors,
  Icon,
  Modal,
  Select,
  TalentechIcons,
  TalentechSizes,
  Typography,
} from "@talentech/components";
import { ITags } from "tag-types";
import { useTranslation } from "react-i18next";
import {
  ICommonTypes,
  useAddProjectCandidateTagMutation,
} from "@store/services/projectsCandidatesService";
import {
  ICandidateTagList,
  IProjectCandidateDetails,
} from "project-candidate-types";

interface IAddTagsModalProps extends ICommonTypes {
  showModal: boolean;
  handleCloseModal: () => void;
  tags: ITags[];
  candidateTagList: ICandidateTagList[];
  candidateDetails: IProjectCandidateDetails;
}

interface ITagOption {
  value: number;
  label: string;
}

const AddTagsModal: FC<IAddTagsModalProps> = ({
  showModal,
  handleCloseModal,
  tags,
  candidateTagList,
  projectId,
  candidateId,
  candidateDetails,
}) => {
  const [selectedTag, setSelectedTag] = useState<ITagOption | null>(null);
  const [addTag, { isLoading }] = useAddProjectCandidateTagMutation();
  const { t } = useTranslation();
  const availableTags = tags.filter(
    (tag) =>
      !candidateTagList.some((candidateTag) => tag.id === candidateTag.tagId)
  );

  const tagOptions = availableTags.map((tag) => ({
    label: tag.name,
    value: tag.id,
  }));

  const handleAddTag = async () => {
    if (selectedTag) {
      await addTag({
        projectId,
        candidateId,
        tagId: selectedTag.value,
        comment: "",
      });
      handleCloseModal();
    }
  };

  const handleCancel = () => {
    setSelectedTag(null);
    handleCloseModal();
  };

  return (
    <Modal
      title={t("mobapp.canddetails.addtags")}
      subtitle={`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
      buttons={[
        <Button
          disabled={isLoading || !tagOptions.length}
          isLoading={isLoading}
          className="w-100"
          color={ButtonColors.PRIMARY}
          onClick={handleAddTag}
          label={t("mobapp.common.save")}
          key="add"
        />,
        <Button
          className="w-100"
          outline
          onClick={handleCancel}
          label={t("mobapp.common.cancel")}
          key="cancel"
        />,
      ]}
      showModal={showModal}
      onClose={handleCloseModal}
    >
      {tagOptions.length ? (
        <>
          <Typography className="fw-semi-bold mb-1 lh-1 small">
            {t("mobapp.canddetails.addtagsfromlist")}
          </Typography>
          <Select
            placeholder={t("mobapp.canddetails.placeholder.addatag")}
            className="w-100 me-3"
            onChange={setSelectedTag}
            options={tagOptions}
            isSearchable
            isClearable
          />
        </>
      ) : (
        <Typography variant="h5">
          {t("mobapp.canddetails.alreadyadded.alltags")}
        </Typography>
      )}
    </Modal>
  );
};

const TagOption: FC<{ tag: ITagOption }> = ({ tag }) => (
  <Box flex className="align-items-center">
    <Icon
      className="me-1 text-100"
      iconSize={TalentechSizes.SMALL}
      icon={TalentechIcons.REGULAR_01_INTERFACE_ESSENTIALS_TAGS_BOOKMARKS_TAGS}
    />
    <Typography className="text-100">{tag.label}</Typography>
  </Box>
);

export default AddTagsModal;
