import {
  BootstrapSizes,
  Box,
  Button,
  ButtonColors,
  Grid,
  Icon,
  ListGroup,
  Row,
  TalentechIcons,
  TalentechSizes,
  Typography,
} from "@talentech/components";
import React, { FC, useState } from "react";
import { useHistory, useParams } from "react-router";

import { AppRoutes } from "@utils/enums";
import { IEachProjectCandidate } from "project-candidate-types";

import ProjectCandidatesListGroup from "./ProjectCandidatesListGroup";
import { useTranslation } from "react-i18next";
interface ProjectCandidatesListProps {
  handleSortToggle: () => void;
  projectCandidates: IEachProjectCandidate[];
}

const SORT_ICONS = (
  <>
    <Icon
      iconSize={TalentechSizes.MEDIUM}
      icon={
        TalentechIcons.LIGHT_22_SOCIAL_MEDIAS_REWARDS_RATINGS_RATING_RATING_STAR
      }
    />
    <Icon
      iconSize={TalentechSizes.MEDIUM}
      icon={TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_NAVIGATE_CARROT_DOUBLE}
    />
  </>
);

const ProjectCandidatesList: FC<ProjectCandidatesListProps> = ({
  projectCandidates,
  handleSortToggle,
}) => {
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const [projectCandidateList, setprojectCandidateList] = useState<number[]>(
    []
  );
  const handleCandidateClick = (candidate: IEachProjectCandidate) => {
    const projectCandidateList = projectCandidates.map((item) => item.id);
    setprojectCandidateList(projectCandidateList);

    history.push({
      pathname:
        AppRoutes.ProjectCandidate.replace(":projectId", projectId).replace(
          ":candidateId",
          candidate.id.toString()
        ) +
        "?t=" +
        Date.now(),
      state: { projectCandidateList },
    });
  };

  return (
    <Box className="bg-light square rounded p-3">
      <Row className="mb-2">
        <Grid xs={12}>
          <Box
            flex
            className="w-100 justify-content-between align-items-center"
          >
            <Typography variant="h2" className="mb-0">
              {t("mobapp.candlist.label.applicants")}
            </Typography>
            <Button
              onClick={handleSortToggle}
              className="btn-outline-primary"
              aria-label="Sort rating acending-decending"
              color={ButtonColors.TRANSPARENT}
              icon={SORT_ICONS}
              size={BootstrapSizes.SMALL}
            />
          </Box>
        </Grid>
      </Row>
      <Box>
        {!!projectCandidates.length ? (
          projectCandidates.map((candidate) => (
            <ListGroup
              onClick={() => handleCandidateClick(candidate)}
              key={candidate.id}
              items={ProjectCandidatesListGroup(candidate)}
              flush
            />
          ))
        ) : (
          <Typography variant="h6">
            {t("mobapp.candlist.label.nocandidates")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default ProjectCandidatesList;
