import { Box, Grid, PageTitle, TalentechIcons } from "@talentech/components";
import React, { FC, useCallback, useReducer, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import ProjectCandidateLoader from "@components/Loaders/ProjectCandidateLoader";
import {
  useGetProjectCandidateDetailsQuery,
  useGetProjectCandidatesQuery,
} from "@store/services/projectsCandidatesService";
import { useGetTagsQuery } from "@store/services/tagsService";
import { AppRoutes, ModalTypes } from "@utils/enums";
import { ICandidateProfileActions } from "modal-types";
import {
  IAttachmentList,
  IEachProjectCandidate,
} from "project-candidate-types";
import AddNoteModal from "./modals/AddNoteModal";
import AddTagsModal from "./modals/AddTagsModal";
import MediaPreviewModal from "./modals/MediaPreviewModal";
import StarRatingModal from "./modals/StarRatingModal";
import VideoPreviewModal from "./modals/VideoPreviewModal";
import ProjectCandidateHead from "./fragments/ProjectCandidateHead";
import CandidateProfileBody from "./fragments/ProjectCandidateBody";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface LocationState {
  projectCandidateList: number[];
}
const projectCandidateReducer = (
  state: ICandidateProfileActions,
  action: ICandidateProfileActions
): ICandidateProfileActions => ({
  modalOpen: action.modalOpen,
});

const ProjectCandidate: FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();

  const { projectId } = useParams<{ projectId: string }>();

  // Extracting candidateId from the URL without the query parameter
  const candidateIdWithQuery = useParams<{ candidateId: string }>().candidateId;
  const candidateId = candidateIdWithQuery.split("?")[0];

  const { t } = useTranslation();
  const [attachmentFile, setAttachmentFile] = useState<IAttachmentList | null>(
    null
  );

  const [modal, dispatch] = useReducer(projectCandidateReducer, {
    modalOpen: ModalTypes.NONE,
  });

  let { projectCandidateList = [] } = location.state || {};

  // When location.state is undefined, Fetched CandidateList from Api
  if (!location.state) {
    const {
      projectCandidates,
      isProjectCandidatesFetching,
    } = useGetProjectCandidatesQuery(
      { id: projectId },
      {
        selectFromResult: ({ data, isFetching }) => ({
          projectCandidates: data?.elements || [],
          isProjectCandidatesFetching: isFetching,
        }),
      }
    );
    projectCandidateList = projectCandidates.map((item) => item.id);
  }

  // If still projectCandidate is undefined,Redirect to home screen
  if (!projectCandidateList) {
    history.push("/" + "?t=" + Date.now());
    return null; // Prevent further execution
  }

  const {
    candidateDetails,
    candidateTagList,
    isCandidateDetailsFetching,
    refetch, // Destructure the refetch function from the hook result
  } = useGetProjectCandidateDetailsQuery(
    { projectId, candidateId },
    {
      selectFromResult: ({ data, isFetching }) => ({
        candidateDetails: data,
        candidateTagList: data?.candidateTagList || [],
        isCandidateDetailsFetching: isFetching,
      }),
      skip: !projectCandidateList,
    }
  );

  const { tags } = useGetTagsQuery(undefined, {
    selectFromResult: ({ data }) => ({ tags: data || [] }),
    skip: !candidateDetails,
  });

  const currentIndex = projectCandidateList.findIndex(
    (b) => b === candidateDetails?.id
  );

  const handleCloseModal = useCallback(
    () => dispatch({ modalOpen: ModalTypes.NONE }),
    []
  );

  const handleOpenModal = useCallback(
    ({ modalOpen }: ICandidateProfileActions) => dispatch({ modalOpen }),
    []
  );

  const handleSwitchCandidate = (indexOffset: number) => {
    const nextCandidateId = projectCandidateList[currentIndex + indexOffset];
    if (nextCandidateId) {
      if (!location.state) {
        // No state found in location, so dont send state
        history.push(
          AppRoutes.ProjectCandidate.replace(":projectId", projectId).replace(
            ":candidateId",
            nextCandidateId.toString()
          ) +
            "?t=" +
            Date.now()
        );
      } else {
        //State found in location, so post state to component
        history.push({
          pathname:
            AppRoutes.ProjectCandidate.replace(":projectId", projectId).replace(
              ":candidateId",
              nextCandidateId.toString()
            ) +
            "?t=" +
            Date.now(),
          state: { projectCandidateList },
        });
      }
    }
  };

  // if (isCandidateDetailsFetching)
  //   return <ProjectCandidateLoader />;

  return (
    <>
      <AddNoteModal
        showModal={modal.modalOpen === ModalTypes.ADD_NOTE}
        {...{
          candidateDetails,
          handleCloseModal,
          projectId,
          candidateId,
        }}
      />
      <AddTagsModal
        showModal={modal.modalOpen === ModalTypes.ADD_TAGS}
        {...{
          candidateDetails,
          handleCloseModal,
          candidateTagList,
          tags,
          projectId,
          candidateId,
        }}
      />
      <MediaPreviewModal
        showModal={modal.modalOpen === ModalTypes.MEDIA_PREVIEW}
        {...{ attachmentFile, handleCloseModal }}
      />
      <StarRatingModal
        showModal={modal.modalOpen === ModalTypes.ADD_RATING}
        {...{ candidateDetails, handleCloseModal, projectId, candidateId }}
      />
      <VideoPreviewModal
        showModal={modal.modalOpen === ModalTypes.VIDEO_PREVIEW}
        {...{
          candidateDetails,
          handleCloseModal,
          projectId,
          candidateId,
        }}
      />

      <PageTitle
        title={`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
      />

      <Grid container className="mt-2">
        <Box className="bg-light square rounded p-4">
          <ProjectCandidateHead
            isFirst={currentIndex === 0}
            isSingleCandidate={projectCandidateList.length === 1}
            isLast={currentIndex === projectCandidateList.length - 1}
            {...{ candidateDetails, handleSwitchCandidate, handleOpenModal }}
          />
          <CandidateProfileBody
            {...{ candidateDetails, setAttachmentFile, handleOpenModal }}
          />
        </Box>
      </Grid>
    </>
  );
};

export default ProjectCandidate;
