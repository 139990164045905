import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { loadUser } from "redux-oidc";
import reducer from "@store/reducer";
import userManager from "@auth/UserManager";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { projectsApi } from "./services/projectsService";
import { projectsCandidatesApi } from "./services/projectsCandidatesService";
import { tagsApi } from "./services/tagsService";

const store = configureStore({
  reducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["redux-oidc/USER_FOUND"],
        ignoredPaths: ["oidc.user", "importApi"],
      },
    }),
    projectsApi.middleware,
    projectsCandidatesApi.middleware,
    tagsApi.middleware,
  ],
});

setupListeners(store.dispatch);
loadUser(store, userManager);

export type RootState = ReturnType<typeof store.getState>;

export default store;
