import {
  Avatar,
  TalentechAvatarSizes,
  TalentechIcons,
} from "@talentech/components";
// import { IListGroupItemProps } from "@talentech/components/build/components/ListGroup/ListGroupItem/ListGroupItem";
import { oidcUtils } from "@talentech/login";
import React from "react";
import userManager from "@auth/UserManager";
import { useTranslation } from "react-i18next";

export const getMenuContent = () => {
  const { t } = useTranslation();
  const MenuContent = [
    {
      title: t("mobapp.menu.signout"),
      dropdownItem: true,
      advancedProps: {
        contentImage: (
          <Avatar
            outline
            icon={
              TalentechIcons.BOLD_01_INTERFACE_ESSENTIALS_LOGIN_LOGOUT_LOGOUT
            }
            size={TalentechAvatarSizes.LG}
          />
        ),
      },
      onClick: () => oidcUtils.hardLogout(userManager),
    },
  ];
  // ] as IListGroupItemProps[];

  return MenuContent;
};
