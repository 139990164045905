import userManager from "@auth/UserManager";
import AppSidebar from "@components/AppSidebar/AppSidebar";
import Header from "@components/Header/Header";
import store from "@store/store";
import { LayoutGrid, talentechTheme } from "@talentech/components";
import "@talentech/components/build/index.css";
import { AuthProvider, NavigationPersistor } from "@talentech/login";
import { AppRoutes } from "@utils/enums";
import { enhanceApiRequestsWithAccessToken } from "@utils/httpUtils";
import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import SnackbarProvider from "react-simple-snackbar";
import { OidcProvider } from "redux-oidc";
import { ThemeProvider } from "styled-components";
import Routes from "./routes";
import "./index.css";
import registerServiceWorker from "./serviceWorkerRegistration";

enhanceApiRequestsWithAccessToken();

const RoutedProviders = () => {
  return (
    <>
      <NavigationPersistor />
      <AuthProvider
        loadingScreen={{
          title: "Signing in",
          userManager,
          subtitle: "We need to make last checks and you'll be ready to go :)",
        }}
        oidcCallback={{
          route: AppRoutes.OidcCallback,
          oidCallbackErrorRoute: AppRoutes.Error,
        }}
        navigate={useHistory().push}
      >
        <LayoutGrid header={<Header />} sidebar={<AppSidebar />}>
          <Routes />
        </LayoutGrid>
      </AuthProvider>
    </>
  );
};

ReactDOM.render(
  <ThemeProvider theme={talentechTheme}>
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <SnackbarProvider>
          <Router>
            <RoutedProviders />
          </Router>
        </SnackbarProvider>
      </OidcProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById("app")
);
registerServiceWorker();
