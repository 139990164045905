import { combineReducers } from "@reduxjs/toolkit";
import { projectsApiReducer } from "@store/services/projectsService";
import { reducer as oidcReducer } from "redux-oidc";
import { reducer as appNotificationsReducer } from "../features/appNotifications/appNotificationsSlice";
import { projectsCandidatesApiReducer } from "@store/services/projectsCandidatesService";
import { tagsApiReducer } from "@store/services/tagsService";

const reducer = combineReducers({
  oidc: oidcReducer,
  appNotifications: appNotificationsReducer,
  ...projectsApiReducer,
  ...projectsCandidatesApiReducer,
  ...tagsApiReducer,
});

export default reducer;
