import React from "react";
import {
  Box,
  Icon,
  TalentechIcons,
  TalentechSizes,
} from "@talentech/components";

const AccordionItemContent: React.FC<{
  onClick?: any;
  title: string;
  icon: TalentechIcons;
  href?: string;
}> = ({ title, icon, href, onClick }) => {
  return (
    <Box flex className="align-items-center gap-3 mb-3 ">
      <Icon iconSize={TalentechSizes.SMALL} icon={icon} />
      <a
        className="mb-0 lh-lg link-primary text-decoration-underline"
        href={href}
        onClick={onClick}
      >
        {title}
      </a>
    </Box>
  );
};

export default AccordionItemContent;
