import {
  ApplicationBar,
  Box,
  Button,
  Icon,
  TalentechIcons,
} from "@talentech/components";
import { getOidcUser } from "@talentech/login";
import { AppRoutes } from "@utils/enums";
import { User } from "oidc-client";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getMenuContent } from "./MenuContent";

const Header = (props: { toggleSidebar?(): void }) => {
  const user: User = useSelector(getOidcUser);
  const history = useHistory();
  const location = useLocation();

  const isHomePage = useMemo(() => location.pathname === AppRoutes.Home, [
    location.pathname,
  ]);

  const currentPath = window.location.pathname;

  const handleBackButtonClick = () => {
    // Regular expressions for matching URL patterns
    const projectDetailsRegex = /^\/project\/(\d+)$/;
    const candidateDetailsRegex = /^\/project\/(\d+)\/candidate\/(\d+)$/;

    // Match against each URL pattern
    let match;

    if ((match = currentPath.match(projectDetailsRegex))) {
      // User on projectDetails screen,RedirectToProjectList
      history.push("/" + "?t=" + Date.now());
    } else if ((match = currentPath.match(candidateDetailsRegex))) {
      // User on CandidateDetails screen,RedirectToProjectDetails
      const projectId = match[1];
      history.push("/project/" + projectId + "?t=" + Date.now());
    } else {
      // Redirect to Home Screen
      history.push("/" + "?t=" + Date.now());
    }
  };

  return (
    <>
      <ApplicationBar
        firstName={user.profile.given_name}
        lastName=""
        menu={getMenuContent()}
        toolboxItems={[
          !isHomePage && (
            <Box key={1} className="d-flex align-items-center gap-2">
              <Button
                aria-label="back-button"
                outline
                onClick={handleBackButtonClick}
                className="btn-sm"
                icon={
                  <Icon
                    icon={
                      TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_NAVIGATE_NAVIGATION_LEFT_CIRCLE_1
                    }
                  />
                }
              />
            </Box>
          ),
        ]}
        toggleSidebar={props.toggleSidebar}
        avatarUrl={user.profile.picture}
      />
    </>
  );
};

export default Header;
