import React from "react";
import { useTranslation } from "react-i18next";

interface IProjectStatusLoader {
  value: number;
}

const ProjectStatusLoader: React.FC<IProjectStatusLoader> = ({ value }) => {
  let result: string;
  const { t } = useTranslation();

  switch (value) {
    case 1:
      result = t("home.chart.pipeline.statuses.incoming");
      break;
    case 2:
      result = t("home.chart.pipeline.statuses.screening");
      break;
    case 3:
      result = t("home.chart.pipeline.statuses.interview");
      break;
    case 4:
      result = t("home.chart.pipeline.statuses.selectionphase");
      break;
    case 5:
      result = t("home.chart.pipeline.statuses.hired");
      break;
    default:
      result = t("home.chart.pipeline.statuses.ProjectAdded");
  }

  return <div>{result}</div>;
};

export default ProjectStatusLoader;
