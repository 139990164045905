import React, { useState } from "react";
import { Button, ButtonColors, Modal, TextField } from "@talentech/components";
import { IProjectCandidateDetails } from "project-candidate-types";
import { useTranslation } from "react-i18next";
import {
  ICommonTypes,
  useAddProjectCandidateNoteMutation,
} from "@store/services/projectsCandidatesService";

interface IAddNoteModal extends ICommonTypes {
  showModal: boolean;
  handleCloseModal: () => void;

  candidateDetails: IProjectCandidateDetails;
}

const AddNoteModal: React.FC<IAddNoteModal> = ({
  showModal,
  handleCloseModal,
  projectId,
  candidateId,
  candidateDetails,
}) => {
  const [noteContent, setNoteContent] = useState<string>();
  const [addNote, { isLoading }] = useAddProjectCandidateNoteMutation();
  const { t } = useTranslation();
  const handleSubmit = async () => {
    if (noteContent) {
      await addNote({
        projectId,
        candidateId,
        note: noteContent,
      });

      handleCloseModal();
    }
  };

  return (
    <Modal
      title={t("mobapp.canddetails.addnotes")}
      subtitle={`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
      buttons={[
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          className="w-100"
          color={ButtonColors.PRIMARY}
          onClick={handleSubmit}
          label={t("mobapp.canddetails.addnote")}
          key="add note"
        />,
        <Button
          className="w-100"
          outline
          onClick={handleCloseModal}
          label={t("mobapp.common.cancel")}
          key="cancel"
        />,
      ]}
      showModal={showModal}
      onClose={handleCloseModal}
    >
      <TextField
        placeholder={t("mobapp.canddetails.placeholder.addnote")}
        rows="5"
        type="textarea"
        onChange={(e) => setNoteContent(e.target.value)}
      />
    </Modal>
  );
};

export default AddNoteModal;
