import React, { useState } from "react";
import { Button, Modal, Typography } from "@talentech/components";
import styled from "styled-components";
import { IProjectCandidateDetails } from "project-candidate-types";
import { useTranslation } from "react-i18next";

interface IVideoPreviewModal {
  showModal: boolean;
  handleCloseModal: () => void;
  candidateDetails: IProjectCandidateDetails;
}

const VideoPreviewModal: React.FC<IVideoPreviewModal> = ({
  showModal,
  handleCloseModal,
  candidateDetails,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("mobapp.model.title.videocv")}
      subtitle={`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
      buttons={[
        <Button
          outline
          onClick={handleCloseModal}
          label={t("common.close")}
          key="Close"
        />,
      ]}
      showModal={showModal}
      onClose={handleCloseModal}
    >
      <video controls width="100%" height="auto">
        <source src={candidateDetails?.videoCvUrl} type="video/mp4" />
        {t("mobapp.model.videocv.browser.warning")}
      </video>
    </Modal>
  );
};

export default VideoPreviewModal;
