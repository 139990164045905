import { compareDesc, differenceInDays, formatISO } from "date-fns";
import { getOidcRegionalSettings } from "@talentech/login";
import { formatDateToHRIDLocale } from "./locales/localeUtils";
import store from "@store/store";
import { utcToZonedTime } from "date-fns-tz";

export const formatDatesWithoutTimezone = (dates: Record<string, Date>) =>
  Object.entries(dates).reduce((acc, curr) => {
    acc = {
      ...acc,
      [curr[0]]: curr[1]
        ? formatISO(new Date(curr[1]), { representation: "date" })
        : null,
    };
    return acc;
  }, {});

export const flatDate = (item: any) => {
  if (Array.isArray(item)) {
    return item.length === 1 ? item.pop() : item;
  }
  return item;
};

export const flattenDates = (data: Record<string, any>) => {
  return Object.entries(data).reduce((acc, curr) => {
    return {
      ...acc,
      [curr[0]]: flatDate(curr[1]),
    };
  }, {});
};

export const getTalentechIdLocale = (): string =>
  getOidcRegionalSettings(store.getState() as never);

export const serializeFormDates = (
  data: Record<string, Date | Date[] | [Date]>
) => {
  const dates = flattenDates(data);
  return formatDatesWithoutTimezone(dates);
};

export const getDifferenceInDaysBetweenDates = (
  date: Date | string
): number => {
  const now = formatISO(new Date());
  const difference = differenceInDays(new Date(date), new Date(now));
  return Number(difference) + 1;
};

export const getDifferenceInYearsBetweenDates = (
  date: Date | string
): string => {
  const now = formatISO(new Date());
  const difference = Math.abs(
    new Date(now).getFullYear() - new Date(date).getFullYear()
  );
  return String(difference);
};

export const isToday = (date: Date | string): boolean =>
  new Date().getDate() === new Date(date).getDate();

export const formatDateIfExist = (date: string | Date) =>
  date ? formatDateToHRIDLocale(new Date(date)) : null;

export const formatDateToUTC = (date: Date) => utcToZonedTime(date, "UTC");

export const sortByDate = (dateLeft: Date, dateRight: Date) =>
  compareDesc(dateLeft, dateRight);

export const localizedDateString = (dateString: string): string => {
  // Define a regular expression to match date and time components
  const regex = /(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2})/;

  // Use the exec method to extract matched groups
  const match = regex.exec(dateString);

  if (match) {
    const [, day, month, year, hours, minutes] = match;
    const formattedDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
      parseInt(hours, 10),
      parseInt(minutes, 10)
    );

    // Format the date for locale like "en-US","da-DK" etc.
    const formattedDateString = new Intl.DateTimeFormat(
      getTalentechIdLocale(),
      {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // Use 24-hour clock
      }
    ).format(formattedDate);

    return formattedDateString;
  } else {
    "Invalid date format";
  }
};
