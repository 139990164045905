import { oidcUtils } from "@talentech/login";

const apiScopes = [
  // "talentech-tmv2-employee-portal-scope",
  // "talentech-organization-api-scope",
  // "talentech-analytics-scope",
  // "talentech-auditlogs-scope",
  // "talentech-notification-api-scope",
  // "talentech-documents-api-scope",
  // "talentech-dashboard-api-scope",
  // "talentech-imports-api-scope",
  // "talentech-roles-api-scope",
  // "talentech-custom-fields-api",
  "hrmts-recruiter-service-api-scope",
];

const userManager = oidcUtils.createOidcUserManager(
  {
    client_id: "hrmts-recruiter-react-pwa",
    silent_redirect_uri: `${window.location.protocol}//${
      window.location.hostname
    }${
      window.location.port ? `:${window.location.port}` : ""
    }/silent_renew.html`,
    redirect_uri: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? `:${window.location.port}` : ""
    }/#callback`,
  },
  apiScopes
);

export default userManager;
