import { Button, Modal, Typography } from "@talentech/components";
import { IAttachmentList } from "project-candidate-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import styled from "styled-components";

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

interface IMediaPreviewModalProps {
  showModal: boolean;
  handleCloseModal: () => void;
  attachmentFile: IAttachmentList;
}

const MediaPreviewModal: React.FC<IMediaPreviewModalProps> = ({
  showModal,
  handleCloseModal,
  attachmentFile,
}) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const { t } = useTranslation();

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }) => {
      setNumPages(numPages);
      setPageNumber(1);
    },
    []
  );

  const changePage = useCallback((offset: number) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }, []);

  return (
    <Modal
      title={attachmentFile?.documentName}
      buttons={[
        <Button outline onClick={handleCloseModal} label="Close" key="close" />,
        <Button
          outline
          disabled={pageNumber <= 1}
          onClick={() => changePage(-1)}
          label="Previous"
          key="prev"
        />,
        <Button
          outline
          disabled={pageNumber >= (numPages ?? 0)}
          onClick={() => changePage(1)}
          label="Next"
          key="next"
        />,
      ]}
      showModal={showModal}
      onClose={handleCloseModal}
    >
      <PDFDocumentWrapper>
        <Document
          file={attachmentFile?.documentUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            key={`page_${pageNumber}`}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
        <Typography className="text-center">
          {t("mobapp.common.page")} {pageNumber} {t("mobapp.common.of")}{" "}
          {numPages ?? "--"}
        </Typography>
      </PDFDocumentWrapper>
    </Modal>
  );
};

export default MediaPreviewModal;
