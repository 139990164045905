import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "@talentech/components";
import { apiUrl } from "@utils/urlsUtils";
import {
  IProjectCandidateDetails,
  IProjectCandidates,
  IProjectCandidatesParams,
  IProjectCandidateDetailsParams,
} from "project-candidate-types";

enum TagTypes {
  PROJECT_CANDIDATES = "PROJECT_CANDIDATES",
}

export interface ICommonTypes {
  projectId: string;
  candidateId: string;
}

interface IAddCandidateNote extends ICommonTypes {
  note: string;
}
interface IAddCandidateTag extends ICommonTypes {
  tagId: number;
  comment: string;
}

interface ISetCandidateCommitteeRank extends ICommonTypes {
  hiringCommitteeRankId: number;
  comment: string;
}

export const projectsCandidatesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: apiUrl }),
  reducerPath: "v",
  tagTypes: [TagTypes.PROJECT_CANDIDATES],
  endpoints: (build) => ({
    getProjectCandidates: build.query<
      IProjectCandidates,
      IProjectCandidatesParams
    >({
      query: (params) => ({
        method: "GET",
        url: `/v1/Projects/${params.id}/candidates`,
        params,
      }),
    }),
    getProjectCandidateDetails: build.query<
      IProjectCandidateDetails,
      IProjectCandidateDetailsParams
    >({
      query: ({ projectId, candidateId }) => ({
        method: "GET",
        url: `/v1/Projects/${projectId}/candidates/${candidateId}`,
      }),
      providesTags: [TagTypes.PROJECT_CANDIDATES],
    }),
    addProjectCandidateNote: build.mutation<unknown, IAddCandidateNote>({
      query: (data) => ({
        method: "POST",
        url: `/v1/Candidates/addCandidateNote`,
        data,
      }),
      invalidatesTags: [TagTypes.PROJECT_CANDIDATES],
    }),
    addProjectCandidateTag: build.mutation<unknown, IAddCandidateTag>({
      query: (data) => ({
        method: "POST",
        url: `/v1/Candidates/addCandidateTag`,
        data,
      }),
      invalidatesTags: [TagTypes.PROJECT_CANDIDATES],
    }),
    setCandidateHiringCommitteeRank: build.mutation<
      unknown,
      ISetCandidateCommitteeRank
    >({
      query: (data) => ({
        method: "POST",
        url: `/v1/Candidates/setCandidateHiringCommitteeRank`,
        data,
      }),
      invalidatesTags: [TagTypes.PROJECT_CANDIDATES],
    }),
  }),
});

export const {
  useGetProjectCandidatesQuery,
  useLazyGetProjectCandidatesQuery,
  useGetProjectCandidateDetailsQuery,
  useLazyGetProjectCandidateDetailsQuery,
  useAddProjectCandidateNoteMutation,
  useAddProjectCandidateTagMutation,
  useSetCandidateHiringCommitteeRankMutation,
} = projectsCandidatesApi;

export const projectsCandidatesApiReducer = {
  [projectsCandidatesApi.reducerPath]: projectsCandidatesApi.reducer,
};
