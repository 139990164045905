import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Row,
  PageTitle,
} from "@talentech/components";
import { getOidcLanguage } from "@store/selectors/oidc";
import { useLazyGetProjectsQuery } from "@store/services/projectsService";
import useDebounce from "../../hooks/useDebounce";
import i18n from "../../i18n";
import ProjectsList from "./fragments/ProjectsList";
import { useTranslation } from "react-i18next";

const Recruitments: React.FC = () => {
  const [typedProjectName, setTypedProjectName] = useState<string>("");
  const debouncedSearch = useDebounce(typedProjectName, 200);
  const oidcLang = useSelector(getOidcLanguage);
  const { t } = useTranslation();

  const [fetchProjects, { projects, isFetching }] = useLazyGetProjectsQuery({
    selectFromResult: ({ data, ...rest }) => ({
      projects: data?.elements || [],
      ...rest,
    }),
  });

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTypedProjectName(event.target.value);
    },
    []
  );

  useEffect(() => {
    i18n.changeLanguage(oidcLang);
  }, [oidcLang]);

  useEffect(() => {
    fetchProjects({ projectName: debouncedSearch });
  }, [fetchProjects, debouncedSearch]);

  return (
    <>
      <PageTitle title={t("mobapp.header.recruitments")} />
      <Grid container className="mt-2">
        <Box className="bg-light square rounded p-4">
          <Row>
            <Grid xs={12}>
              <TextField
                placeholder={t("mobapp.placeholder.search.projectname")}
                onChange={handleChange}
              />
            </Grid>
            {!isFetching ? (
              projects.length ? (
                <ProjectsList projects={projects} />
              ) : (
                <Box flex className="justify-content-center mt-4">
                  <Typography variant="h6" className="mb-0">
                    {t("mobapp.projects.norecord")}
                  </Typography>
                </Box>
              )
            ) : (
              <Box className="placeholder-glow">
                {[...Array(8).keys()].map((index) => (
                  <span key={index} className="placeholder col-12 py-3 mb-2" />
                ))}
              </Box>
            )}
          </Row>
        </Box>
      </Grid>
    </>
  );
};

export default Recruitments;
