import React, { FC, useCallback, useMemo } from "react";
import {
  Accordion,
  BootstrapSizes,
  Box,
  Button,
  ButtonColors,
  TalentechIcons,
  Typography,
} from "@talentech/components";
import { ModalTypes } from "@utils/enums";
import format from "date-fns/format";
import { ICandidateProfileActions } from "modal-types";
import {
  IAttachmentList,
  IProjectCandidateDetails,
} from "project-candidate-types";
import AccordionItemContent from "./AccordionItemContent";
import { useTranslation } from "react-i18next";
import { getTalentechIdLocale, localizedDateString } from "@utils/datesUtils";

interface CandidateProfileBodyProps {
  candidateDetails: IProjectCandidateDetails;
  setAttachmentFile: React.Dispatch<React.SetStateAction<IAttachmentList>>;
  handleOpenModal: ({ modalOpen }: ICandidateProfileActions) => void;
}

const CandidateProfileBody: FC<CandidateProfileBodyProps> = ({
  candidateDetails,
  handleOpenModal,
  setAttachmentFile,
}) => {
  const handleDocumentClick = useCallback(
    (attachment: IAttachmentList) => {
      setAttachmentFile(attachment);
      handleOpenModal({ modalOpen: ModalTypes.MEDIA_PREVIEW });
    },
    [handleOpenModal, setAttachmentFile]
  );
  const { t } = useTranslation();
  const StatusContent = useMemo(
    () => (
      <>
        <div>
          <Box flex className="align-items-center gap-3 mb-3">
            <Box>
              <Typography className="mb-0 lh-1">
                {candidateDetails?.status}
              </Typography>
            </Box>
          </Box>
        </div>
      </>
    ),
    [candidateDetails]
  );
  const documentsContent = useMemo(
    () =>
      candidateDetails?.attachmentList.map((attachment) => (
        <AccordionItemContent
          key={attachment.documentId}
          onClick={() => handleDocumentClick(attachment)}
          icon={
            TalentechIcons.LIGHT_16_FILES_FOLDERS_COMMON_FILES_COMMON_FILE_TEXT_DOUBLE_2
          }
          title={attachment.documentName}
        />
      )),
    [candidateDetails, handleDocumentClick]
  );

  const notesContent = useMemo(
    () =>
      candidateDetails?.candidateNotes.map((note) => {
        return (
          <div key={note.id}>
            <Box flex className="align-items-center gap-3 mb-3">
              <Box>
                <Typography className="fw-semi-bold mb-0">
                  {note.createdByName}
                </Typography>
                <Typography className="mb-0 lh-1 small">
                  {localizedDateString(note.created)}
                </Typography>
              </Box>
            </Box>
            <Box className="my-3 overflow-auto">
              <Typography className="mb-0">{note.note}</Typography>
            </Box>
          </div>
        );
      }),
    [candidateDetails]
  );

  const contactInformationContent = useMemo(
    () => (
      <>
        {!!candidateDetails?.phone && (
          <AccordionItemContent
            icon={
              TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_HELP_HEADPHONES_CUSTOMER_SUPPORT
            }
            title={candidateDetails?.phone}
            href={`tel:${candidateDetails?.phone}`}
          />
        )}
        {!!candidateDetails?.email && (
          <AccordionItemContent
            icon={
              TalentechIcons.LIGHT_19_EMAILS_EMAIL_ACTIONS_EMAIL_ACTION_UNREAD
            }
            title={candidateDetails?.email}
            href={`mailto:${candidateDetails?.email}`}
          />
        )}
        {!!candidateDetails?.linkedInPublicProfileUrl && (
          <a
            href={candidateDetails.linkedInPublicProfileUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <AccordionItemContent
              icon={TalentechIcons.BOLD_53_LOGOS_SOCIAL_MEDIAS_LINKEDIN}
              title={`${candidateDetails?.firstName} ${candidateDetails?.lastName}`}
            />
          </a>
        )}
      </>
    ),
    [candidateDetails]
  );

  const items = useMemo(
    () =>
      [
        {
          content: StatusContent,
          title: t("mapp.candidateinfo.menutitle.status"),
          id: "0",
        },
        {
          content: (
            <>
              {notesContent}
              <Button
                onClick={() =>
                  handleOpenModal({ modalOpen: ModalTypes.ADD_NOTE })
                }
                className="w-100"
                color={ButtonColors.PRIMARY}
                label={t("mobapp.canddetails.button.addnote")}
                size={BootstrapSizes.XLARGE}
              />
            </>
          ),
          title: t("mobapp.canddetails.label.notes"),
          id: "1",
        },
        {
          content: contactInformationContent,
          title: t("mobapp.canddetails.label.contactinformation"),
          id: "2",
        },
        candidateDetails?.attachmentList.length !== 0 && {
          content: documentsContent,
          title: t("mobapp.canddetails.label.documents"),
          id: "3",
        },
      ].filter(Boolean),
    [contactInformationContent, documentsContent, handleOpenModal, notesContent]
  );

  return <Accordion accordionItems={items} collapseMultiple flush />;
};

export default CandidateProfileBody;
